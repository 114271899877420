.nowrap {
    white-space: nowrap;
    padding-left: 8px;
    padding-right: 8px;
}

.table-summary-row {
    border-top: 2px solid #ccc;
}

td {
    padding: 8px;
}