.striped-row-even {
    background-color: #ececec;
}

.striped-row-odd {
    background-color: #ffffff;
}

.fund-card .ant-card-body {
    padding: 8px;
}

.status-card .ant-card-body {
    padding: 8px;
}