@font-face {
    font-family: 'Segoe UI';
    src: local('Segoe UI'), local('SegoeUI');
}

/* Allowing the use of the Segoe UI font in SVGs to export to PNG */
svg text {
    font-family: 'Segoe UI';
}

.ant-tabs-content,
.ant-tabs-content-top,
.ant-tabs-tabpane,
.ant-tabs-tabpane-active {
    display: flex;
    flex-grow: 1;
    width: 100%;
    height: 100%;
}

.ant-tabs-nav {
    margin-bottom: 0 !important;
}

.ant-form-item {
    margin-bottom: 0 !important;
}

.ant-tabs-nav-list {
    margin-left: 30px;
}

.ant-table-tbody>tr:hover>td {
    background-color: #f8958e !important;
}

.ant-table-tbody>tr:nth-child(odd) {
    background-color: #ececec;
}

.ant-table-tbody>tr.ant-table-row-level-0:nth-child(odd) {
    background-color: #ececec !important;
}

.ant-table-tbody>tr.ant-table-row:nth-child(odd)>td {
    background-color: #ececec !important;
}

.ant-table {
    border-radius: 6px;
    border: 1px solid #cbcbcc !important;
}

.jodit-add-new-line {
    display: none !important;
}

.no-padding {
    padding: 0 !important;
}

.editable-cell-value-wrap {
    padding: 4px 11px;
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
}

.cell-without-padding .ant-table-cell {
    padding: 0 !important;
}

p {
    margin: 0;
}

ul {
    margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
h7 {
    margin: 0;
}